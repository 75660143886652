@import "variables";
@import "mixin";

.light {
  background-color: $preview_wrapper_bg_light;
  color: black;

  .site {
    background-color: #fff;
  }
}

.dark {
  background-color: $preview_wrapper_bg_dark;
  color: white;

  .site {
    background-color: $preview_bg_dark;
  }
}

.pink {
  background-color: $preview_wrapper_bg_pink;
  color: white;

  .site {
    background-color: $preview_bg_pink;
  }
}

.site_wrapper {
  .site {
    position: relative;
    @include center(column);
    justify-content: flex-start;
    margin: 0 120px;
    padding: 0 60px 70px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);

    .loader {
      all: unset;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .header_banner {
      @include center;
      gap: 6px;
      row-gap: 10px;
      width: calc(100% + 360px);
      background-color: #000;
      color: #fff;
      padding: 20px 10px;
      margin-bottom: 40px;

      span {
        text-align: center;
        margin-right: 10px;

        & > a {
          margin-left: 6px;
        }
      }

      a {
        text-decoration: underline;
        color: white;
      }

      .report_link {
        @include center;
        gap: 6px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &:first-child {
        justify-content: flex-start;
      }
    }

    .header_image {
      width: 60%;
      max-width: 100%;
      height: auto;
      border-radius: 10px;
      margin-top: 40px;
    }

    .title_container {
      align-self: center;
      text-align: center;
      font-size: 28.8px;

      h1 {
        font-weight: 700;
        margin: 0;
      }
    }

    .content {
      width: 100%;
      align-self: center;
      text-align: center;
      font-size: 19.2px;
      font-weight: 400;
    }

    .section_images {
      width: 100%;
      @include center;
      justify-content: space-between;
      flex-wrap: wrap;
      column-gap: 60px;
      row-gap: 20px;
      padding: 0 40px;

      img {
        width: 30%;
        max-width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }

    h2 {
      align-self: flex-start;
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
    }

    .header_image,
    .title_container,
    .content,
    .section_images,
    .attachment {
      margin-bottom: 10px;
    }

    .footer {
      margin-bottom: 0;
      position: absolute;
      bottom: 0;
    }

    @media screen and (max-width: 798px) {
      padding-bottom: 60px;

      .title_container {
        font-size: 1.5rem;
      }

      .content {
        font-size: 1rem;
      }

      h2 {
        font-size: 1.2rem;
      }
    }

    @media screen and (max-width: 500px) {
      margin: 0;
      padding: 0 10px 60px;

      .header_banner {
        width: calc(100% + 20px);
      }

      .header_image {
        width: 100%;
      }

      .title_container {
        font-size: 1.2rem;
      }

      .content {
        font-size: 0.8rem;
        padding: 0 20px;
      }

      .section_images {
        padding: 0 20px;

        img {
          width: 45%;
        }
      }

      h2 {
        font-size: 1rem;
      }
    }
  }
}
