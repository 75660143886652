.section {
background-color: black;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  position: relative;

  .container {
    box-sizing: border-box;
    background: linear-gradient(
    to bottom,
    rgba(#001C0D, 0),
    rgba(#001C0D, 1),
    rgba(#001C0D, 0));
    padding: 100px 20px;
    position: relative;

    @media (min-width: 1400px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 1400px) {
      max-width: 100%;
    }
    @media screen and (max-width: 600px) {
      padding: 100px 20px;
    }

    h1 {
      color: rgb(255, 255, 255, 255);
      text-align: center;
      margin: 20px 0;
      font-size: 40px;
      font-weight: 800;

      @media screen and (max-width: 500px) {
        font-size: 24px;
      }
    }

    p {
      color: rgb(255, 255, 255, 255);
      text-align: center;
      margin: 20px auto;
      width: 450px;
      font-size: 16px;
      font-weight: 400;

      @media screen and (max-width: 500px) {
        margin: 20px 0;
        width: auto;
        font-size: 14px;
      }
    }

    .wrapper {
      margin: auto;
      background: radial-gradient(
        196.51% 196.51% at 50% 55.23%,
        #002d15 0%,
        rgba(0, 6, 3, 0) 100%
      );
      width: 537px;
      padding: 20px 40px;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      color: white;

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      .input_group {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .limit {
          width: 100%;
          margin: 5px;
          text-align: right;
          font-size: 12px;
          font-weight: 300;
        }

        &:nth-child(4) {
          div {
            @media screen and (max-width: 430px) {
              flex-direction: column;

              textarea {
                width: 100%;
              }
            }
          }
        }

        div {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          label {
            flex-basis: 40%;
            font-size: 18px;
            font-weight: 600;
          }

          input {
            flex-basis: 60%;
            height: 30px;
            border-radius: 8px;
            border: none;
            padding: 10px;
            box-sizing: border-box;

            &:focus {
              outline: none;
            }
          }

          textarea {
            flex-basis: 60%;
            border-radius: 8px;
            border: none;
            padding: 10px;
            box-sizing: border-box;

            &:focus {
              outline: none;
            }
          }
        }

        @media screen and (max-width: 430px) {
          div {
            label {
              font-size: 16px;
              min-width: 80px;
            }
            input {
              flex-basis: 70%;
              min-width: 120px;
            }

            textarea {
              flex-basis: 70%;
              min-width: 120px;
            }
          }
        }
      }

      button {
        font-family: "Lexend Tera", sans-serif;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
