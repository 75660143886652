@import "variables";
@import "mixin";

.section {
  position: relative;
  @include center;
  min-height: 100vh;
  width: 100%;

  .container {
    margin: 0 auto;
    @include center;
    flex-wrap: wrap;
    padding: 2rem 10rem;
    gap: 10px;

    .auth_image {
      @include center(column);
      gap: 2rem;
      flex: 0.4;

      img {
        width: 300px;
        height: auto;
      }

      @media screen and (min-width: 400px) and (max-width: 998px) {
        img {
          width: 200px;
        }
      }

      @media screen and (max-width: 400px) {
        img {
          width: 60%;
        }
      }

      @media screen and (max-width: 330px) {
        img {
          width: 50%;
        }
      }
    }

    .auth_box {
      @include center(column);
      gap: 1.8rem;
      flex: 0.5;
      z-index: 1;

      .auth_header {
        width: 80%;
        text-align: center;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;

        @media screen and (min-width:500px) and (max-width:1086px){
          font-size: 18px;
        }

        @media screen and (max-width:500px) {
          font-size: 18px;
        }
      }

      .body {
        width: 100%;
        padding: 1.6rem 3.2rem;
        @include center(column);
        gap: 1rem;
        border-radius: 8px;
        background-color: rgba(217, 217, 217, 1);

        strong {
          font-weight: 500;
          font-size: 16px;
        }

        .login_button {
          font-size: 12px;
          width: fit-content;
          @include center;
          gap: 0.4rem;
          color: black;

          svg {
            stroke-width: 0.7;
            width: 30px;
            height: auto;
            stroke: black;
          }
        }
      }

      .caption {
        display: none;
      }
    }

    .caption {
      @include center(column);
      text-align: center;

      .link {
        font-size: 14px;
        a {
          all: unset;
          cursor: pointer;
          letter-spacing: 0.8px;
          color: $dark_green;
        }
      }

      i {
        margin-top: 0.4rem;
        font-size: 12px;
      }

      cite {
        font-style: normal;
        font-size: 10px;
        font-weight: 300;
        margin-top: 1rem;
      }
    }

    @media (min-width: 1400px) {
      margin: auto;
    }

    @media screen and (max-width: 1400px) {
      max-width: 100%;
    }

    @media screen and (max-width: 1040px) {
      padding: 2rem 8rem;
    }

    @media screen and (max-width: 900px) {
      padding: 0;
      width: 80%;
      flex-direction: column;
      gap: 0.8rem;

      .auth_box {
        order: 2;
        flex: 1;

        .body {
          padding: 1.2rem 2.4rem;

          p{
            font-size: 10px;
          }

          .login_button {
            transform: scale(0.8);

            svg {
              width: 30px;
            }
          }
        }
      }

      .auth_image {
        order: 1;
        flex: 1;

        .caption {
          display: none;
        }
      }

      .auth_box{
        .caption {
          @include center(column);
          text-align: center;
    
          .link {
            font-size: 14px;
            a {
              all: unset;
              cursor: pointer;
              letter-spacing: 0.8px;
              color: $dark_green;
            }
          }
    
          i {
            margin-top: 0.4rem;
            font-size: 12px;
          }
    
          cite {
            font-style: normal;
            font-size: 10px;
            font-weight: 300;
            margin-top: 1rem;
          }
        }
      }
    }

    @media screen and (max-width: 448px) {
      .auth_box {
        .body {
          padding: 1.2rem 1.6rem;

          p{
            font-size: 10px;
          }

          strong {
            font-size: 12px;
          }

          .login_button {
            transform: scale(0.7);

            svg {
              width: 30px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
