@import "variables";

.header {
  width: 100%;
  background-color: black;
  position: fixed;
  z-index: 2;

  .container {
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    // padding: 10px 20px 0px 20px;

    @media (min-width: 1400px) {
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 1400px) {
      max-width: 100%;
    }
    .navbar_logo {
      height: 60px;
      width: 96px;
      margin: 5px 25px;
      cursor: pointer;
      margin-top: 10px;

      @media screen and (max-width: 576px) {
        width: 65px;
        height: auto;
      }
    }

    .list {
      width: 70%;
      padding: 0px 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 30px;
      list-style: none;
      font-size: 14px;
      font-weight: normal;

      @media (max-width: 1014px) {
        display: none;
      }

      a {
        cursor: pointer;
        color: white;
        text-decoration: none;
        position: relative;
        &:hover{
          color: #78d03e;
        }

        &::after{
          content:"";
          position: absolute;
          border-radius: 8px;
          left: 0;
          bottom: 0;
          margin-bottom: -5px;
          height: 3px;
          width: 0;
          background-color: #78d03e;
          transition: all ease-in-out 0.1s;
        }
  
        &:hover::after{
          width: 100%;
        }
      }
    }

    .connect {
      margin: 15px 60px 15px 0px;
      padding: 0px 65px;
      border: 1px solid white;
      border-radius: 8px;
      background: transparent;
      transition: background-color 0.3s ease-out;
      cursor: pointer;
      color: white;

      &:hover {
        background-color: rgba(0,87,40,0.6);
      }

      @media (max-width: 1014px) {
        display: none;
      }
    }

    .open {
      cursor: pointer;
      display: none;
      margin: 10px;
      background: transparent;
      border: none;
      font-size: 28px;
      color: white;
      margin-right: 20px;

      @media (max-width: 1014px) {
        display: block;
      }

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }

    .mobile_navbar {
      width: 100%;
      height: 100vh;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px;
      text-align: center;
      max-width: 1400px;

      .close {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right:10px;
        display: none;
        margin: 10px;
        background: transparent;
        border: none;
        font-size: 28px;
        color: white;
        margin-right: 20px;

        @media (max-width: 1014px) {
          display: block;
        }

        @media (max-width: 576px) {
          font-size: 20px;
        }
      }

      .mobile_connect {
        margin: 80px;
        padding: 10px 35px;
        border: 1px solid white;
        border-radius: 10px;
        background: transparent;
        cursor: pointer;
        margin-bottom: 20px;
        color: white;

        &:hover {
          background-color: rgba(0,87,40,0.6);
        }
      }

      .mobile_list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 12px;
        font-weight: normal;

        a {
          cursor: pointer;
          color: white;
          text-decoration: none;
          position: relative;
          &:hover{
            color: #78d03e;
          }

          &::after{
            content:"";
            position: absolute;
            border-radius: 8px;
            left: 0;
            bottom: 0;
            margin-bottom: -5px;
            height: 3px;
            width: 0;
            background-color: #78d03e;
            transition: all ease-in-out 0.1s;
          }
    
          &:hover::after{
            width: 100%;
          }
        }
      }
    }
  }
}
