* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;

  @media screen and (max-width: 750px) {
    font-size: 12px;
  }
}


body {
  overflow-x: hidden;
  background: white;
  margin: 0 auto;
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

a,
button {
  cursor: pointer;
  font-family: "Lexend Tera", sans-serif;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

label[aria-disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
}

*:disabled, *[aria-disabled="true"] * {
  outline: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.btn {
  position: relative;
  padding: 0.8rem 1.8rem;
  background-color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.4rem;

  @media screen and (max-width: 750px) {
    font-size: 8px;
  }

  @media screen and (min-width: 750px) and (max-width: 1040px) {
    font-size: 10px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.8;
    box-shadow: none;
  }
}

.gradient_btn {
  position: relative;
  padding: 16px 38px;
  background: linear-gradient(91deg, #005728 0%, #78d03e 51%, #005728 100%);
  box-shadow: 0px 0px 4px #005728;
  background-size: 200%;
  border: none;
  border-radius: 8px;
  color: white;
  transition: background-position 0.3s ease;
  font-size: 12px;

  @media screen and (max-width: 750px) {
    font-size: 8px;
  }

  @media screen and (min-width: 750px) and (max-width: 1040px) {
    font-size: 10px;
  }

  &:hover:not(:disabled) {
    background-position: right center;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.8;
    box-shadow: none;
  }
}

.bg_gradient{

  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: linear-gradient(
  to bottom,
  rgba(#001C0D, 0),
  rgba(#001C0D, 1),
  rgba(#001C0D, 0));
}

.blocked {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}
