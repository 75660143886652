@import "mixin";
@import "variables";

.file {
  position: relative;
  padding: 20px 40px;
  @include center;
  overflow: unset;
  width: 100%;
  border-radius: 10px;
  background-color: #d9d9d9;

  .controls {
    position: absolute;
    @include center(column);
    gap: 4px;
    top: 10px;
    right: 10px;
    background-color: transparent;

    .icon {
      @include center;
      cursor: pointer;
      padding: 10px;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #fff;
      }

      svg {
        width: 16px;
        height: 16px;
        margin: 0;
      }

      &:first-child svg {
        filter: invert(1);
      }

      &:nth-child(2) svg {
        filter: invert(0);
      }
    }
  }

  img,
  video,
  embed,
  audio,
  iframe {
    width: 100%;
    height: auto;
    border: none;
  }

  audio {
    height: 50px;
    margin-bottom: 20px;
  }

  model-viewer {
    min-height: 500px;
    width: clamp(300px, 100%, 100%);

    @media screen and (max-width: 500px) {
      min-height: 300px;
    }
  }

  embed {
    height: 1000px;
    // max-height: 1000px;

    @media screen and (max-width: 500px) {
      height: 500px;
      // max-height: 500px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 20px 10px;
  }
}

.bg_black {
  background-color: #000;

  .controls .icon {
    &:hover {
      background-color: lighten($color: #000, $amount: 40%);
    }

    &:first-child svg {
      filter: invert(0);
    }

    &:nth-child(2) svg {
      filter: invert(1);
    }
  }
}

.fullscreen_file {
  width: 100vw;
  height: 100vh;
  padding: 0;
  border-radius: 0;

  .controls {
    top: 20px;
    right: 20px;
    z-index: 100;
  }

  model-viewer {
    min-height: 100vh;
    width: 100vw;
  }

  embed {
    height: 100vh;
  }

  @media screen and (max-width: 500px) {
    padding: 0;
  }
}

.anchor {
  width: 100%;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  @include center;
  justify-content: space-between;
  gap: 10px;

  div {
    @include center;
    gap: 6px;
  }
}
